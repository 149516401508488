import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, bindable, bindingMode, customElement, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {I18N} from "aurelia-i18n";

@customElement('sub-product-component')
@inject(Element, ShoppingCart, Backend, Router, I18N)
export class SubProductComponent {
  element;
  backend;
  shoppingCart;
  router;
  i18N;

  categories;
  products = [];
  visibleProducts;
  visibleCategories;
  productForModal;

  @bindable initValues = {};

  @bindable({defaultBindingMode: bindingMode.twoWay}) sum;
  @bindable({defaultBindingMode: bindingMode.twoWay}) chosenProducts;
  @bindable({defaultBindingMode: bindingMode.twoWay}) selectedProductCategorySeoKey;
  @bindable showEveryTime = false;
  @bindable showBuyButton = false;
  @bindable showQuantity = true;
  @bindable showCategoryFilter = true;
  @bindable initFromCart = true;
  @bindable supplierIdFilter = null;
  @bindable({defaultBindingMode: bindingMode.twoWay}) isEmpty;
  @bindable cratesInsteadOfSubProducts = false;
  @bindable showUnavailable = false; // show unavailable products, even when not searching
  @bindable showProductDetailsInModal = true;

  @observable({changeHandler: 'searchChanged'}) search = '';

  constructor(element, shoppingCart, backend, router, i18N) {
    this.element = element;
    this.shoppingCart = shoppingCart;
    this.backend = backend;
    this.router = router;
    this.i18N = i18N;
  }


  attached() {
    let initValues = this.initValues; // referencing 'this' didn't work in the inner forEach.
    this.backend.SubProductCategorizedQueryHandler_handle({
      supplierId: this.supplierIdFilter,
      cratesInsteadOfSubProducts: this.cratesInsteadOfSubProducts,
      isAvailable: null
    })
      .then(result => {
        // Store backend result
        this.categories = result.categories;
        this.products = result.products;

        // Synthetic category for search results
        this.searchResult_syntheticCategory = {
          productCategoryId: 'fakeIdSearchResults',
          name: this.i18N.tr('screen.general.searchResult'),
          iconKey: null,
          isSyntheticCategoryForSearchResults: true
        };
        this.categories.unshift(this.searchResult_syntheticCategory);

        // Synthetic category for featured products: add category if any products are featured.
        let featuredProducts = this.products.filter(product => product.isFeaturedOnSubProducts);
        if (featuredProducts.length > 0) {
          this.featuredProducts_syntheticCategory = {
            productCategoryId: 'fakeIdFeaturedProducts',
            name: this.i18N.tr('domain.product.featuredCategoryName'),
            iconKey: 'heart',
            isSyntheticCategoryForFeaturedProducts: true
          };
          this.categories.unshift(this.featuredProducts_syntheticCategory);
        }
        this.visibleCategories = this.categories;

        // Expand the requested category, or the first category if none is requested
        if (this.selectedProductCategorySeoKey) {
          let selectedCategory = this.categories.filter(category => category.seoKey === this.selectedProductCategorySeoKey)[0];
          this.selectCategory(selectedCategory);
        } else {
            this.selectCategory(this.categories[0]);
        }

        // Initialize quantity on each product.
        this.products.forEach(subProduct => {
          let subProductInitialValue = initValues[subProduct.productId];
          if (subProductInitialValue) {
            subProduct.quantity = initValues[subProduct.productId].quantity;
            subProduct.everyTime = initValues[subProduct.productId].everyTime;
          } else {
            // Initialize to zero. Otherwise we get NaN when we try to increment.
            subProduct.quantity = 0
          }
        });
        this.recalculateSum();

        // Init search state
        this.searchChanged()
      });
  }

  everyTimeChanged(product) {
    this.recalculateSum();
  }

  quantityChanged() {
    this.recalculateSum();
  }

  recalculateSum() {
    // Sum
    this.sum = this.products
      .map(p => p.quantity * p.priceIncVat)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    // Items
    this.chosenProducts =
      this.products
      // Do not filter out products with zero quantity if they were present in the initially loaded values.
      // The reason for this is that we need to know that we should delete that line.
      .filter(product => product.quantity > 0 || this.initValues[product.productId])
      .map(product => ({
        productId: product.productId,
        name:  product.name,
        quantity:  product.quantity,
        priceIncVat:  product.priceIncVat,
        everyTime: product.everyTime
      }));
  }

  @observable({changeHandler: 'selectedCategoryChanged'}) selectedCategoryId;
  scrollFromNowOn = false
  selectedCategoryChanged() {
    let selectedCategory = this.categories.filter(category => category.productCategoryId === this.selectedCategoryId)[0];
    this.__selectCategory(selectedCategory, this.scrollFromNowOn);
    this.scrollFromNowOn = true;
  }

  selectCategory(category, scroll = false) {
    this.selectedCategoryId = category.productCategoryId;
    this.__selectCategory(category, scroll);
  }

  __selectCategory(category, scroll = false) {
    // Store selected category
    this.selectedCategory = category;

    // Deactivate all categories, but the newly selected
    this.categories.forEach(c => c.active = false);
    category.active = true;

    // Set two-way bindable to communicate selected category outside of component
    this.selectedProductCategorySeoKey = category.seoKey;

    // Filter products
    if (this.showCategoryFilter) {
      // Synthetic category for featured product?
      if (category.isSyntheticCategoryForSearchResults) {
        this.visibleProducts = this.products.filter(product => product.searchMatch);
      }
      // Synthetic category for search results?
      else if (category.isSyntheticCategoryForFeaturedProducts) {
        this.visibleProducts = this.products
          .filter(p => p.isFeaturedOnSubProducts)
          .sort((p1,p2) => p1.featuredProductsSort - p2.featuredProductsSort);
      }
      // Real product category chosen
      else {
        this.visibleProducts = this.products.filter(p => p.productCategoryId === category.productCategoryId);
      }
    } else {
      // If we are not showing category filter, show all products, without filtering on category.
      this.visibleProducts = this.products;
    }

    // Update sort
    this.visibleProducts.sort((p1, p2) =>
      p2.isAvailable - p1.isAvailable
      || p2.productSearchMatch - p1.productSearchMatch
      || p1.presentationSort - p2.presentationSort);

    // Update bindable for outside to see
    this.isEmpty = this.visibleProducts.length === 0;

    if (scroll) {
      this.categoriesElement.scrollIntoView(true);
    }
  }

  clearAllQuantities() {
    // Clear all
    this.products.forEach(product => product.quantity = 0);

    // Process new quantities.
    this.quantityChanged();
  }

  searchChanged() {
    // Search in products
    for (const product of this.products) {
      product.searchMatch =
           this.getSearchMatch(this.search, product.name)
        || this.getSearchMatch(this.search, product.supplierName);
    }

    // Search in categories
    for (const category of this.categories) {
      category.searchMatch = this.getSearchMatch(this.search, category?.name);
    }

    // Change visible categories
    this.visibleCategories = this.categories.filter(category => {
      if (this.search) {
        // While searching: show search-result-category + categories matching search
        return category.isSyntheticCategoryForSearchResults || category.searchMatch;
      } else {
        // While not searching: show all categories except search-result-category
        return !category.isSyntheticCategoryForSearchResults;
      }
    });

    // Show the synthetic search result category
    if (this.search) {
      this.selectCategory(this.searchResult_syntheticCategory);
    } else {
      this.selectCategory(this.categories[0]);
    }
  }

  getSearchMatch(needle, haystack) {
    // If the search term is empty, do not consider it a match.
    if (!needle) {
      return false;
    }

    // Regex - search
    return new RegExp('.*' + needle + '.*', 'gi').test(haystack);
  }
}
